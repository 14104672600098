import React, { useEffect, useState } from 'react';
import Link from 'components/link';
import ImageLoader from 'components/image-loader';
import AspectRatio from 'components/aspect-ratio';
import Button from 'components/control/button';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';
import { useThemeContext } from 'contexts/theme';

import { ThemeNames } from 'types/themes';
import type { IUserLocation } from 'components/home-page/location';

export interface Props {
  type: string;
  userLocation: IUserLocation;
  isWidget?: boolean;
  redirectUrl?: string;
}

export default function CTACard({ type, userLocation, isWidget, redirectUrl }: Props) {
  const [moduleUrl, setModuleUrl] = useState(redirectUrl || '');
  const [ctaText, setCTAText] = useState('');
  const { themeName } = useThemeContext();
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;

  const getModuleUrl = () => {
    if (type === 'available') {
      setModuleUrl(`/${userLocation?.slug ? userLocation.slug + '-' : '' }real-estate`);
      setCTAText('Filter by price, property type, square footage, and more to find homes for sale in your area.');
    } else if (type === 'sold') {
      setModuleUrl('/search?status=not-available-sold');
      setCTAText('View sold prices for GTA properties dating back to 2003.');
    } else if (type === 'featured') {
      setModuleUrl('/houses');
      setCTAText('Filter by price, property type, square footage, and more to find homes for sale in your area.');
    }
  };

  useEffect(() => {
    if (!redirectUrl) {
      getModuleUrl();
    } else {
      setModuleUrl(redirectUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, redirectUrl]);

  return (
    <div className={buildClassName(styles.component, isExpTheme && styles['exp-home'])} data-testid={testIds.ctaCard}>
      <div className={styles.top}>
        <AspectRatio ratio="4:3">
          <ImageLoader src='/next/assets/images/brush-stroke-2.png' className={styles.image} alt='Brush stroke' />
          <div className={styles.text}>
            <p>Looking for more properties?</p>
            {!isWidget && <Link href={moduleUrl}><Button label="View More" theme="primary" /></Link>}
            {isWidget && <a href={moduleUrl} target="_blank" rel="noreferrer"><Button label="View More" theme="primary" /></a>}
          </div>
        </AspectRatio>
      </div>
      <div className={styles.bottom}>
        <p>{ctaText}</p>
      </div>
    </div>
  );
}
